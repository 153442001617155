<template>
  <div class="container-fluid text-center vh-100" v-if="!isLoaded">
    <HMSpinner class="mt-4" />
  </div>
  <Product v-else />
</template>

<script setup>
import Product from '@/components/product/Product.vue';
import { useProductStore } from '@/stores';
import { onMounted } from 'vue';
import api from '~/api/products';

const route = useRoute();
const router = useRouter();
const productId = route.params.product_id;
const { $studioURL } = useNuxtApp();
const productStore = useProductStore();

try {
  const { data: productBasic } = await useAsyncData('productBasic', () => api.getProductBasic($studioURL, productId))

  useSeoMeta({
    ogTitle: `${productBasic.value.product_name} | ${productBasic.value.studio_name}`,
    ogImage: productBasic.value.cover_file?.image_url,
  })
} catch (error) {
  console.error(error);
}

function checkForRedirect() {
  if (
    !['studio-bundled-product', 'studio-product'].includes(route.name)
  )
    return;

  if (productStore.product?.purchased) {
    if (productStore.product.has_private_sessions) {
      router.replace({
        name: 'studio-product-private-sessions',
      });
    } else if (productStore.product.has_content) {
      router.replace({
        name: 'studio-product-content',
      });
    } else if (productStore.product.has_lessons) {
      router.replace({
        name: 'studio-product-lessons',
      });
    } else if (productStore.product.has_playlists) {
      router.replace({
        name: 'studio-product-playlists',
      });
    } else if (productStore.product.events?.length) {
      router.replace({
        name: 'studio-product-events',
      });
    } else if (productStore.product.bundled_products?.length) {
      router.replace({
        name: 'bundle-included-content',
      });
    } else {
      router.replace({
        name: 'studio-product-about',
      });
    }
  } else {
    router.replace({
      name: 'studio-product-about',
    });
  }
}

async function trackProductView() {
  try {
    await useProductStore().trackProductView({
      studioURL: $studioURL,
      productId: productId,
    });
  } catch (e) {
    console.log('Error tracking product view');
  }
}

const isLoaded = ref(false);
onMounted(async () => {
  await productStore.getProductDetail({
    studioURL: $studioURL,
    productId: productId,
  });
  trackProductView();
  checkForRedirect();
  isLoaded.value = true;
});

definePageMeta({
  name: 'studio-product',

  isStudio: true,
});
</script>
